import { Router } from "@reach/router";
import * as React from "react";
import { GlobalStyles } from "../../components/GlobalStyles";
import { Footer } from "../../components/PageUtils/Footer";
import { Header } from "../../components/PageUtils/Header";
import SEO from "../../components/seo";
import { GalleryRoute } from "../../components/ImageGallery/GalleryRoute";

const GalleryPage = () => {
  return (
    <React.Fragment>
      <SEO title="Gallery" keywords={[`wedding`, `jack`, `nicola`]} />
      <GlobalStyles />
      <Header />
      <Router basepath="/gallery">
        <GalleryRoute path="/:galleryList" />
      </Router>
      <Footer />
    </React.Fragment>
  );
};

export default GalleryPage;
