import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { ImageGallery } from ".";

interface GalleryRouteProps extends RouteComponentProps {
  galleryList?: string;
}

export const GalleryRoute = React.memo<GalleryRouteProps>(props => {
  return <ImageGallery imageSet={props.galleryList} />;
});
